import { Component, DestroyRef, ElementRef, OnChanges, OnInit, inject, input, output } from '@angular/core';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldConfig } from './models/field-config.interface';
import { DynamicFieldDirective } from './dynamic-field/dynamic-field.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MessageService } from 'primeng/api';

@Component({
  exportAs: 'dynamicForm',
  selector: 'lib-shared-dynamic-form',
  template: `
    <form class="dynamic-form row" [formGroup]="form" (ngSubmit)="onSubmit()">
      @for (item of items; track trackControls($index, item)) {
        <ng-container libSharedDynamicFormField [config]="item" [group]="form"> </ng-container>
      }
      <button type="submit" class="invisible"></button>
    </form>
  `,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DynamicFieldDirective],
})
export class DynamicFormComponent implements OnInit, OnChanges {
  private messageService = inject(MessageService);
  private elementRef = inject(ElementRef);
  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);

  config = input<FieldConfig[]>([]);
  submitForm = output<unknown>();

  form = this.fb.group({} as Record<string, AbstractControl>);

  // rendered config items
  items: FieldConfig[] = [];
  // config items that are used as form value
  controls: FieldConfig[] = [];

  ngOnInit() {
    this.createForm();
    this.watchFormChanges();
  }

  ngOnChanges() {
    if (this.items.length) {
      this.updateForm();
    }
  }

  trackControls(i: number, v: FieldConfig) {
    return v.name;
  }

  onSubmit() {
    // trigger validation
    this.form.patchValue(this.form.getRawValue() ?? {});
    if (this.form.valid) {
      this.submitForm.emit(this.form.value);
      return;
    }
    this.form.markAllAsTouched();
    const invalidFields = Object.entries(this.form.controls ?? {})
      .map(([k, c]) => (c.valid ? '' : this.config().find(f => f.name === k)?.label))
      .filter(Boolean);
    this.messageService.add({
      severity: 'error',
      summary: 'Invalid values for:',
      detail: invalidFields.join('\n'),
      styleClass: 'pre-lined',
    });
    this.scrollToFirstInvalidField();
  }

  private createForm() {
    this.items = [...this.config()];
    this.updateControls();
    this.controls.forEach(c => this.form.addControl(c.name, this.createControl(c)));
    this.updateForm();
  }

  private updateForm() {
    this.items = this.config()
      .map(f => f.dependsOn?.(this.form, f) ?? f)
      .filter(({ skip }) => !skip);
    this.updateControls();

    const controlNames = this.controls.map(c => c.name);
    const formControlNames = Object.keys(this.form.controls);
    const controlsToRemove = formControlNames.filter(n => !controlNames.includes(n));
    const controlsToAdd = this.controls.filter(c => !formControlNames.includes(c.name));
    controlsToRemove.forEach(c => this.form.removeControl(c));
    controlsToAdd.forEach(c => this.form.addControl(c.name, this.createControl(c)));
  }

  private updateControls() {
    this.controls = this.items
      .filter(({ type }) => !['button', 'divider'].includes(type ?? ''))
      .map(item => ({ ...item, value: this.form.value[item.name] ?? item.value, labelType: item.labelType ?? 'float' }));
  }

  private createControl(config: FieldConfig) {
    if (config.control) {
      return config.control;
    }
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }

  private watchFormChanges() {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.updateForm());
  }

  private scrollToFirstInvalidField() {
    const invalidFields = this.elementRef.nativeElement.querySelectorAll('.ng-invalid');
    const firstInvalidField = invalidFields[0];
    if (!firstInvalidField) {
      return;
    }
    firstInvalidField.scrollIntoView({ behavior: 'smooth' });
  }
}
