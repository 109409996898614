@if (!data()) {
  <span>No data for dialog</span>
}
@if (data()) {
  @if (!data().containerOnly) {
    <div class="dialog-header" cdkDrag cdkDragRootElement=".p-dialog" cdkDragBoundary=".p-component-overlay" cdkDragHandle>
      <div #header>
        <ng-content select="[header]"></ng-content>
      </div>
      @if (header?.childNodes?.length === 0) {
        <h6 class="dialog-title">{{ data().title }}</h6>
      }
      <div (click)="close(false)" class="dialog-close"><i class="fa fa-close"></i></div>
    </div>
  }
  <div class="dialog-body">
    @if (data().message) {
      <p [innerHtml]="data().message"></p>
    }
    <div #body>
      <ng-content select="[body]"></ng-content>
    </div>
    @if (body?.childNodes?.length === 0) {
      <div>
        @if (!data().fields?.length && !data().message) {
          <div class="flex mt-4 p-5 bg-light rounded justify-content-center">Loading...</div>
        }
        <lib-shared-dynamic-form #dynamicForm [config]="data().fields ?? []" (submitForm)="onSubmit()"></lib-shared-dynamic-form>
      </div>
    }
  </div>
  <div class="dialog-footer">
    <div #footer>
      <ng-content select="[footer]"></ng-content>
    </div>
    @if (footer?.childNodes?.length === 0) {
      <div>
        @if (data().cancelLabel !== '') {
          <button [disabled]="loading()" type="button" class="btn btn-link link" (click)="close(this.data().cancelValue)">
            {{ data().cancelLabel || 'Cancel' }}
          </button>
        }
        @for (b of data().extraButtons; track b) {
          @if (b.type === 'cancel') {
            <button [disabled]="loading()" type="button" class="btn btn-link link ms-2" (click)="close(b.result)">
              {{ b.label }}
            </button>
          } @else {
            <lib-shared-ui-submit-btn
              class="ms-2"
              [loading]="loading()"
              (btnClick)="b.result ? close(b.result) : dynamicForm()?.onSubmit()"
              >{{ b.label }}</lib-shared-ui-submit-btn
            >
          }
        }
        @if (data().confirmLabel !== '') {
          <lib-shared-ui-submit-btn class="ms-2" [loading]="loading()" (btnClick)="dynamicForm()?.onSubmit()" [autofocus]="true">{{
            data().confirmLabel || 'Save'
          }}</lib-shared-ui-submit-btn>
        }
      </div>
    }
  </div>
}
