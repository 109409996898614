import { AfterViewInit, Component, DestroyRef, HostListener, inject, input, output, signal, viewChild } from '@angular/core';
import { DynamicFormComponent } from '@mca/shared/feature-dynamic-form';
import { DialogComponentProperties } from '@mca/shared/domain';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogService } from './dialog.service';
import { SubmitBtnComponent } from '@mca/shared/ui';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { outputToObservable, takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-shared-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [CdkDrag, CdkDragHandle, DynamicFormComponent, SubmitBtnComponent],
})
export class DialogComponent implements AfterViewInit {
  private dialogService = inject(DialogService);
  private dialogRef = inject(DynamicDialogRef, { optional: true });
  private dialogConfig = inject(DynamicDialogConfig, { optional: true });
  private destroyRef = inject(DestroyRef);

  loading = signal(false);
  out$ = new Subject<unknown>();
  // used to display closing confirmation message
  closing = output<boolean>();
  closing$ = outputToObservable(this.closing);
  data = input<DialogComponentProperties>(this.dialogConfig?.data);

  dynamicForm = viewChild(DynamicFormComponent);

  onSubmit() {
    this.loading.set(true);
    this.out$.next(this.dynamicForm()?.form.getRawValue());
  }

  @HostListener('document:keydown.escape')
  close(customResult: unknown) {
    const dirty = !this.dynamicForm() || this.dynamicForm()?.form.dirty;

    if (!this.dialogRef) {
      this.dialogService.dialogReference?.close(dirty);
    }
    if (!customResult && dirty) {
      this.closing.emit(true);
    } else {
      this.dialogRef?.close(customResult);
      return;
    }
    this.out$.next(customResult);
  }

  ngAfterViewInit() {
    const valueChanges$ = this.data().valueChanges$;
    if (valueChanges$) {
      this.dynamicForm()
        ?.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() =>
          valueChanges$.next({ form: this.dynamicForm()?.form as FormGroup, value: this.dynamicForm()?.form.getRawValue() }),
        );
    }
  }
}
