import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Injector, TemplateRef, Type } from '@angular/core';
import { FormButtonComponent } from '../form-button/form-button.component';
import { FormInputComponent } from '../form-input/form-input.component';
import { FormSelectComponent } from '../form-select/form-select.component';
import { FormMultiselectComponent } from '../form-multiselect/form-multiselect.component';
import { FormCheckboxComponent } from '../form-checkbox/form-checkbox.component';
import { FormTextareaComponent } from '../form-textarea/form-textarea.component';
import { FormToggleComponent } from '../form-toggle/form-toggle.component';
import { FormDatepickerComponent } from '../form-datepicker/form-datepicker.component';
import { DividerFormComponent } from '../divider-form/divider-form.component';
import { FormMessageComponent } from '../form-message/form-message.component';
import { FormMultiInputComponent } from '../form-multi-input/form-multi-input.component';
import { FormMultiSelectsComponent } from '../form-multi-selects/form-multi-selects.component';

export const fieldComponents = {
  button: FormButtonComponent,
  input: FormInputComponent,
  select: FormSelectComponent,
  multiselect: FormMultiselectComponent,
  checkbox: FormCheckboxComponent,
  textarea: FormTextareaComponent,
  toggle: FormToggleComponent,
  datepicker: FormDatepickerComponent,
  divider: DividerFormComponent,
  message: FormMessageComponent,
  multiInput: FormMultiInputComponent,
  multiSelects: FormMultiSelectsComponent,
  template: null,
  component: null,
};

export type FieldType = keyof typeof fieldComponents;

export interface FieldConfig {
  skip?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  label?: string;
  labelType?: LabelTypeConfig;
  labelHelper?: string;
  tooltip?: TooltipConfig;
  name: string;
  hint?: string;
  options?: OptionsSelectConfig;
  placeholder?: string;
  type?: FieldType;
  inputType?: string;
  inputMask?: string;
  separatorLimit?: string;
  validation?: ValidatorFn[];
  value?: any;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  class?: string;
  template?: TemplateRef<any>;
  injector?: Injector;
  control?: AbstractControl;
  component?: Type<any>;
  componentInputs?: { [key: string]: any };
  componentOutputs?: { [key: string]: (value: any, form: FormGroup, field: FieldConfig) => void };
  // note: form value has precedence over config value
  dependsOn?: (form: FormGroup, field: FieldConfig) => FieldConfig;
}

export interface OptionsSelectConfig {
  items?: any;
  multiple?: boolean;
  bindLabel?: string;
  bindValue?: string;
  clearable?: boolean;
}

export interface TooltipConfig {
  text: string;
  placement?: 'right' | 'left' | 'top' | 'bottom' | undefined;
  icon?: string;
}

export type LabelTypeConfig = 'float' | 'above';
