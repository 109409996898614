import { Component, OnInit, model } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { FieldConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { FormErrorsComponent } from '@mca/shared/ui';
import { NgClass } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'lib-shared-dynamic-form-multi-input',
  templateUrl: 'form-multi-input.component.html',
  standalone: true,
  imports: [OverlayPanelModule, SharedModule, FormsModule, NgClass, FormErrorsComponent],
})
export class FormMultiInputComponent implements Field, OnInit {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);

  get control() {
    return this.group().controls[this.config().name];
  }

  ngOnInit() {
    if (!this.control.value) {
      this.control.setValue([''], { emitEvent: false });
    }
  }

  removeOption(index: number) {
    if (this.control.value.length === 1) {
      this.control.setValue(['']);
      return;
    }
    this.control.setValue((this.control.value as string[]).filter((value, i) => i !== index));
  }
  addOption() {
    this.control.setValue([...(this.control.value || []), ''], { emitEvent: false });
  }
  trackBy(index: number) {
    return index;
  }
}
