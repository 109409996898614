import { Component, computed, model } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { FormErrorsComponent } from '@mca/shared/ui';
import { NgxMaskDirective } from 'ngx-mask';
import { TooltipModule } from 'primeng/tooltip';
import { NgClass } from '@angular/common';

@Component({
  selector: 'lib-shared-dynamic-form-input',
  template: `
    <div class="dynamic-field form-input form-group" [formGroup]="group()" [ngClass]="{ 'ui-float-label': config().labelType === 'float' }">
      @if (config().label) {
        <label>
          {{ config().label }}
          @if (config().required) {
            <span>*</span>
          }
          @if (config().tooltip; as tooltip) {
            <i
              [pTooltip]="tooltip.text"
              [tooltipPosition]="tooltip.placement ? tooltip.placement : 'right'"
              [class]="tooltip.icon ? tooltip.icon : 'fa fa-question-circle p-1 pr-2'"
            ></i>
          }
        </label>
      }
      <input
        type="{{ config().inputType || 'text' }}"
        [attr.placeholder]="config().placeholder"
        [formControlName]="config().name"
        class="form-control"
        [required]="config().required ?? false"
        [mask]="config().inputMask || null"
        [separatorLimit]="config().separatorLimit || ''"
        [readonly]="config().readonly"
      />
      @if (config().hint) {
        <div class="form-text text-muted">
          {{ config().hint }}
        </div>
      }
      <lib-shared-ui-form-errors [control]="control()" [label]="config().label ?? ''"></lib-shared-ui-form-errors>
    </div>
  `,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, TooltipModule, NgxMaskDirective, FormErrorsComponent],
})
export class FormInputComponent implements Field {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);
  control = computed(() => this.group().controls[this.config().name]);
}
