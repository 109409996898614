import { Component, OnInit, model } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { FieldConfig, OptionsSelectConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { FormErrorsComponent } from '@mca/shared/ui';
import { NgClass } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'lib-shared-dynamic-form-multi-selects',
  templateUrl: 'form-multi-selects.component.html',
  standalone: true,
  imports: [OverlayPanelModule, SharedModule, FormsModule, NgClass, FormErrorsComponent, DropdownModule],
})
export class FormMultiSelectsComponent implements Field, OnInit {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);

  options = {} as OptionsSelectConfig;

  get control() {
    return this.group().controls[this.config().name];
  }

  ngOnInit() {
    this.options = {
      items: null,
      bindLabel: 'label',
      bindValue: 'value',
      ...this.config().options,
    };
    if (!this.control.value) {
      this.control.setValue([''], { emitEvent: false });
    }
  }

  removeRow(index: number) {
    if (this.control.value.length === 1) {
      this.control.setValue(['']);
      return;
    }
    this.control.setValue((this.control.value as unknown[]).filter((value, i) => i !== index));
  }
  addRow() {
    this.control.setValue([...(this.control.value || []), null], {
      emitEvent: false,
    });
  }
  trackBy(index: number) {
    return index;
  }
  getOptionLabel(value: any) {
    return this.options.items.find((item: any) => item[this.options.bindValue as string] === value)?.[this.options.bindLabel as string];
  }
}
